import '../../App.css';
import Portfolio from "../../Components/Gallery";
import Offerings from "../Offerings";
import Contact from "../Contact";
import wideshot from './wideshot.jpg';
import motto1 from './motto1.png';
import './index.css';

function Home() {
  return (
    <div className="app-page">
        <div className="home-title">
            NEW ENGLAND WEDDING PHOTOGRAPHER
        </div>

        <Portfolio/>

        <div>
            <img className="motto" alt="" loading="eager" src={wideshot}></img>
        </div>

        <div className="motto-container">
            <img className="motto" alt="" loading="eager" src={motto1}></img>
        </div>

        {window.innerWidth < 600 && <Offerings/>}

        <div className="story-intro">
            <div className="story-column story-left">
                <p> The story of Auré is deeply inspired by the book <em>The Alchemist</em> by <em>Paul Cuelho</em>. </p>
                <p> Similar to the young boy who dreams of <em>gold</em> and ventures into the vast deserts to unveil his true destiny, 
                    I genuinely believe in following my heart. I have a passion for the arts and beauty and finally decides to pursue 
                    my dream as a wedding photographer. </p>
                <p> The word Auré is derived from Aurélio, meaning <em>gold</em> in Portuguese, and symbolizes the personal 
                    pursuit of treasures. In the <em>Hausa</em> language common in parts of Africa, the word Aure means 'wedding.'</p>
            </div>
            <div className="story-column story-right">
                <p> Auré Photography strives to translate beautiful moments in life into lasting memories to be cherished time 
                    and time again. I am determined to provide the best client experience for you as you embark on your first steps 
                    toward marriage. </p>
                <p> Entrust AURÉ to be your storyteller and companion in this journey.
                </p>
            </div>
        </div>

        {window.innerWidth < 600 && <Contact/>}

    </div>
  );
}

export default Home;
