import { Component } from "react";
import '../../App.css';
import './index.css';

import { MasonryPhotoAlbum  }  from "react-photo-album";
import "react-photo-album/masonry.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import WeddingPortfolio from "./Wedding"; 
import EngagementPortfolio from "./Engagement"; 
import LifestylePortfolio from "./Lifestyle";

class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionType: 'wedding',
            lightboxOpen: false,
            imageIndex: 0,
        }
        this.setSessionType = this.setSessionType.bind(this);
        this.setLightboxOpen = this.setLightboxOpen.bind(this);
        this.setLightboxIndex = this.setLightboxIndex.bind(this);
    }

    componentWillUnmount() {
        this.setState({
            lightboxOpen: false,
            imageIndex: 0,
        })
    }

    setSessionType(type) {
        this.setState({ sessionType: type });
    }

    setLightboxOpen(state) {
        this.setState({ lightboxOpen: state });
    }

    setLightboxIndex(index) {
        this.setState({ imageIndex: index, lightboxOpen: true });
    }

    render() {
        return (
            <div className="app-page">
                {/*
                    Uncomment to add portfolios later on
                    <div className="portfolio-type-container">
                        <button className={this.state.sessionType === "wedding"? "offering-button-active" :"offering-button"} onClick={() => this.setSessionType('wedding')}>Wedding</button>
                        <button className={this.state.sessionType === "engagement"? "offering-button-active" :"offering-button"} onClick={() => this.setSessionType('engagement')}>Engagement</button>
                        <button className={this.state.sessionType === "lifestyle"? "offering-button-active" :"offering-button"} onClick={() => this.setSessionType('lifestyle')}>Lifestyle</button>
                    </div> 
                */}

                <div className="gallery-container">
                    <MasonryPhotoAlbum 
                        columns={window.innerWidth < 600 ? 2 : 4}
                        photos={
                            this.state.sessionType === "wedding" ? WeddingPortfolio :
                            this.state.sessionType === "engagement" ? EngagementPortfolio :
                            LifestylePortfolio
                        }
                        onClick={(event) => this.setLightboxIndex(event.index)}
                    />
                </div>

                <Lightbox
                    open={this.state.lightboxOpen}
                    index={this.state.imageIndex}
                    close={() => this.setLightboxOpen(false)}
                    slides={
                        this.state.sessionType === "wedding" ? WeddingPortfolio :
                        this.state.sessionType === "engagement" ? EngagementPortfolio :
                        LifestylePortfolio
                    }
                    styles={{
                        container: {
                            backgroundColor: 'rgba(255, 255, 255, 0.95)',  // Change background to white
                            opacity: 1,                // Optional: Set opacity (default is usually semi-transparent)
                        },
                        button: {
                            filter: 'none',
                        },
                        icon: {
                            color: 'black',
                        },
                        slide: {
                            opacity: 1,  // Ensure the image stays fully visible
                        },
                        // You can also customize other styles like buttons, arrows, etc.
                      }}
                />
            </div>
        );
    }
}

export default Portfolio;
