import Image1 from './1.jpg';
import Image2 from './2.jpg';
import Image3 from './3.jpg';
import Image4 from './4.jpg';
import Image5 from './5.jpg';
import Image6 from './6.jpg';
import Image7 from './7.jpg';
import Image8 from './8.jpg';
import Image9 from './9.jpg';
import Image10 from './10.jpg';
import Image11 from './11.jpg';
import Image12 from './12.jpg';
import Image13 from './13.jpg';
import Image14 from './14.jpg';
import Image15 from './15.jpg';
import Image16 from './16.jpg';
import Image17 from './17.jpg';
import Image18 from './18.jpg';
import Image19 from './19.jpg';
import Image20 from './20.jpg';
import Image21 from './21.jpg';
import Image22 from './22.jpg';
import Image23 from './23.jpg';
import Image24 from './24.jpg';
import Image25 from './25.jpg';
import Image26 from './26.jpg';
import Image27 from './27.jpg';
import Image28 from './28.jpg';
import Image29 from './29.jpg';

const WeddingPortfolio = [
    { src: Image1, width: 500, height: 750 },
    { src: Image2, width: 750, height: 500 },
    { src: Image3, width: 500, height: 750 },
    { src: Image4, width: 500, height: 750 },
    { src: Image5, width: 750, height: 500 },
    { src: Image6, width: 500, height: 750 },
    { src: Image7, width: 500, height: 750 },
    { src: Image8, width: 500, height: 750 },
    { src: Image9, width: 500, height: 750 },
    { src: Image10, width: 500, height: 750 },
    { src: Image11, width: 500, height: 750 },
    { src: Image12, width: 750, height: 500 },
    { src: Image13, width: 500, height: 750 },
    { src: Image14, width: 500, height: 750 },
    { src: Image15, width: 500, height: 750 },
    { src: Image16, width: 750, height: 500 },
    { src: Image17, width: 500, height: 750 },
    { src: Image18, width: 500, height: 750 },
    { src: Image19, width: 500, height: 750 },
    { src: Image20, width: 500, height: 750 },
    { src: Image21, width: 750, height: 500 },
    { src: Image22, width: 500, height: 750 },
    { src: Image23, width: 500, height: 750 },
    { src: Image24, width: 750, height: 500 },
    { src: Image25, width: 500, height: 750 },
    { src: Image26, width: 500, height: 750 },
    { src: Image27, width: 750, height: 500 },
    { src: Image28, width: 500, height: 750 },
    { src: Image29, width: 500, height: 750 },
];

export default WeddingPortfolio;