import { Component } from "react";
import '../../App.css';
import './index.css';

class Offerings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionType: 'wedding',
        }
        this.setSessionType = this.setSessionType.bind(this);
    }

    setSessionType(type) {
        this.setState({ sessionType: type });
    }

    render() {
        return (
            <div className="app-page" id="offerings">

                <div className="offerings-container">
                    <div className="img-col">
                        {   this.state.sessionType === "wedding"? <img alt="" loading="eager" src={require("./wedding.jpg")}></img>:
                            this.state.sessionType === "engagement"? <img alt="" loading="eager" src={require("./engagement.jpg")}></img>:
                            <img alt="" loading="eager" src={require("./lifestyle.jpg")}></img>
                        }
                    </div>
                    <div className="package-col">
                        <div className="package-button-container">
                            <button className={this.state.sessionType === "wedding"? "offering-button-active" :"offering-button"} onClick={() => this.setSessionType('wedding')}>Wedding</button>
                            <button className={this.state.sessionType === "engagement"? "offering-button-active" :"offering-button"} onClick={() => this.setSessionType('engagement')}>Engagement</button>
                            <button className={this.state.sessionType === "lifestyle"? "offering-button-active" :"offering-button"} onClick={() => this.setSessionType('lifestyle')}>Lifestyle</button>
                        </div>

                        <div className="experience-details">
                            <p className="experience-title">
                                {
                                    this.state.sessionType === 'wedding' ? 'THE WEDDING EXPERIENCE' :
                                    this.state.sessionType === 'engagement' ? 'THE FIANCEE EXPERIENCE' :
                                    'THE LIFE EXPERIENCE'
                                }
                            </p>
                            

                            {
                                this.state.sessionType === 'wedding' &&
                                <>
                                    <div className="package-details">
                                        <div className="detail-col">
                                            MINIMALIST
                                            <p style={{marginTop: "15px"}}> Complimentary engagement session </p>
                                            <p> Professional consultations and vision board </p>
                                            <p> Sneak peeks within 48 hours </p>
                                            <p> Online gallery for up to 6 months </p>
                                            <p> Five hours of wedding coverage </p>
                                            <div className="sm-screen-price">
                                                INVESTMENT $1600+
                                            </div>
                                        </div>
                                        <div className="detail-col">
                                            COMPLETE

                                            <p style={{marginTop: "15px"}}> Complimentary engagement session </p>
                                            <p> Professional consultations and vision board </p>
                                            <p> Sneak peeks within 48 hours </p>
                                            <p> Online gallery for up to 1 year </p>
                                            <p> Eight hours of wedding coverage </p>
                                            <p> Second shooter </p>
                                            <p> Engraved USB with high resolation images </p>
                                            {/* <p>Luxury album with 10 8x10 prints</p> */}
                                            <div className="sm-screen-price">
                                                INVESTMENT $2400+
                                            </div>
                                        </div>

                                    </div>

                                    <div className="package-pricing">
                                        <div className="price">
                                            INVESTMENT $1600+
                                        </div>
                                        <div className="price">
                                            INVESTMENT $2400+
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                this.state.sessionType === 'engagement' &&
                                <>
                                    <div className="package-details">
                                        <div className="detail-col">
                                            STANDARD
                                            <p style={{marginTop: "15px"}}> Professional consultations and vision board </p>
                                            <p> Sneak peeks within 48 hours </p>
                                            <p> Online gallery for up to 6 months </p>
                                            <p> Print release </p>
                                            <p> One hour of coverage </p>
                                            <div className="sm-screen-price">
                                                INVESTMENT $250+
                                            </div>
                                        </div>
                                        <div className="detail-col">
                                            DELUXE
                                            <p style={{marginTop: "15px"}}> Professional consultations and vision board </p>
                                            <p> Sneak peeks within 48 hours </p>
                                            <p> Online gallery for up to 6 months </p>
                                            <p> Print release </p>
                                            <p> Two hours of coverage</p>
                                            <p> Outfit change </p>
                                            <div className="sm-screen-price">
                                                INVESTMENT $400+
                                            </div>
                                        </div>

                                    </div>

                                    <div className="package-pricing">
                                        <div className="price">
                                            INVESTMENT $250+
                                        </div>
                                        <div className="price">
                                            INVESTMENT $400+
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                this.state.sessionType === 'lifestyle' &&
                                <>
                                    <div className="package-details">
                                        {/* Write something about loving working with clients through every life stage... */}
                                        <div className="detail-col">
                                            STANDARD
                                            <p style={{marginTop: "15px"}}> Professional consultations and vision board </p>
                                            <p> Online gallery for up to 3 months </p>
                                            <p> Print release </p>
                                            <p> Half hour coverage </p>
                                            <div className="sm-screen-price">
                                                INVESTMENT $150+
                                            </div>
                                        </div>
                                    </div>

                                    <div className="package-pricing">
                                        <div className="price">
                                            INVESTMENT $150+
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="portfolio-button-container">
                            <a href="/portfolio" class="portfolio-button">VIEW PORTFOLIO</a>
                        </div>
                    </div>
                </div>    

                
                <h1 className="questions-title"> Frequently Asked Questions </h1>
                <div className="questions-container">
                    <div className="first-row">
                        <div className="qa-content left-qa">
                            <span className="question">What is your booking process?</span>
                            <p className="answer">
                                Please fill out our <a href={window.innerWidth < 600 ? "#contact" : "/contact"} style={{color: "black"}} >inquiry form</a> with your event date and location and I will reach out within 48 hours to set up a phone call.
                                During this call, I get the chance to learn more about what you are looking for and answer any questions you may have. After our call, I will send a custom proposal based on your unique vision.
                                Your date gets reserved with a contract and a deposit, and I become available to offer guidance on aesthetics and timeline up until your date.
                            </p>
                        </div>

                        <div className="qa-content right-qa">
                            <span className="question">Does your package pricing include travel fees?</span>
                            <p className="answer">
                                My pricing already includes travels fees within 50 miles of Boston so for any location within this radius, no additional fees will be charged.
                                For any destination beyond this, I charge a fee of $1.50 per mile to cover expenses such as gas and hotel accommodation.
                                If you are getting married in California (my family's state), I may charge only a small travel fee that covers my flight ticket dedending on the time of year.
                            </p>
                        </div>
                    </div>

                    <div className="second-row">
                        <div className="qa-content left-qa">
                            <span className="question">Do you take specific shot requests?</span>
                            <p className="answer">
                                Yes, absolutely! You probably have a couple dream shots for your wedding vision. 
                                I would be more than happy to re-create these looks for your wedding day.
                            </p>
                        </div>

                        <div className="qa-content right-qa">
                            <span className="question">When should I expect gallery delivery?</span>
                            <p className="answer">
                                I deliver sneak peeks of 3-7 images within 48 hours after our session.
                                Your full gallery is delivered within 1 month for engagement and within 3 months for weddings.
                            </p>
                        </div>
                    </div>
                </div> 

            {/* 
                Some sample packages here for weddings with what is included
                Elopement: 3-4 hours starting at base price $1200
                Complimentary engagement session
                Engraved USB with high res images 
                Option to purchase luxury customised printed album with 10 6x8 pictures
                Wedding: 6-10 hours starting at base price $1800
                Complimentary engagement session
                Engraved USB with high res images 
                Option to purchase luxury customised printed album with 30 pictures
                Lifestyle/Engagement: $250 per hour
                Minis: $150 
            */}

            </div>
        );
    }
}

export default Offerings;
