import React from "react";
import "./NavBar.css";

export const NavBar = () => {
    return (
        <div className="navbar-container">
            <div className="navbar">
                <nav>
                <li className="nav-item">
                    <a href="/portfolio">Portfolio</a>
                </li>
                <li className="nav-item">
                    <a href={window.innerWidth < 600 ? "/#offerings" : "/offerings"}>Offerings</a>
                </li>
                <li className="nav-item">
                    <a href={window.innerWidth < 600 ? "/#contact" : "/contact"}>Contact</a>
                </li>
                </nav>
            </div>
        </div>
    );
};

/*  Capturing elegance
    Storytelling with style/elegance
    Unique visions beautifully documented
    Individuality beautifully documented
    Stories elegantly told/captured/portrayed(?)
    Elegant storytelling, Memories beautifully documented
    Storytelling (individuality) with beauty and elegance
    Storytelling (individuality) with elegance
    Poetic
*/

/*
We specialize in bringing your vision to life through personalized sessions tailored just for you. 
From the initial consultation to the final edit, we collaborate closely with our clients, 
ensuring every detail reflects your unique style and preferences. Our expertise includes crafting 
mood boards that inspire and guide, suggesting props that enhance your story, and providing expert
guidance on posing to capture your best angles. With our meticulous attention to detail and commitment
to making you feel comfortable and camera-ready, we guarantee a photography experience that exceeds
your expectations. Let us transform your ideas into timeless images that you'll cherish forever.
*/