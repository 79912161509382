import "./Logo.css";
import logo from '../../logo.png';
import orchid from '../../orchid.png';

export const Logo = () => {
    return (
        <div>
            <div className="header-logo">
                <a href="./">
                    <img alt="" width={300} height={150} loading="eager" src={logo}/>
                </a>
            </div>
        </div>
    );
};

